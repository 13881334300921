import { useQuery } from '@apollo/client';
import { Avatars } from 'components/Avatars/Avatars';
import { Card, CardTitle } from 'components/Card/Card';
import { Icon } from 'components/Icon/Icon';
import { cn, normalizeArray } from 'lib/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { USERS } from 'services/users/query';

import { NegativeBalancesModal } from './NegativeBalancesModal';

export const NegativeBalances = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation('dashboard');
    const { data } = useQuery(USERS, {
        variables: {
            hasNegativeBalance: true,
            first: 10,
            isMember: true,
        },
    });

    return (
        <>
            <div onClick={() => setIsOpen(true)}>
                <Card
                    className={cn(
                        'transition-colors border',
                        data?.users?.totalCount && 'hover:bg-rose-50 hover:border-rose-200 hover:cursor-pointer ',
                    )}
                >
                    <div className="flex justify-between">
                        <CardTitle className="text-base">{t('negativeBalancesTitle')}</CardTitle>
                        <Icon name="user-negative" className="text-2xl" />
                    </div>
                    <div className="text-2xl font-semibold">{data?.users?.totalCount || 0}</div>
                    <div className="flex justify-end mt-4 h-[40px]">
                        <Avatars members={normalizeArray(data?.users)} />
                    </div>
                </Card>
            </div>
            <NegativeBalancesModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                members={normalizeArray(data?.users)}
            />
        </>
    );
};
