import { InputMask } from '@react-input/mask';
import { Card, CardSubtitle, CardTitle } from 'components/Card/Card';
import Checkbox from 'components/Checkbox/Checkbox';
import { FormError } from 'components/FormError/FormError';
import { Input } from 'components/Input/Input';
import { Label } from 'components/Label/Label';
import { Select } from 'components/Select/Select';
import dayjs from 'dayjs';
import { SALUTATIONS, USER_LANGUAGES } from 'lib/options';
import { validateDate } from 'lib/utils';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'stores/user.store';

import { ReferencePerson } from '../ReferencePerson/ReferencePerson';

export const PersonalInfo = () => {
    const user = useUserStore(state => state.user);
    const {
        register,
        control,
        formState: { errors },
        setValue,
        watch,
    } = useFormContext();
    const { t: p } = useTranslation('member', { keyPrefix: 'personalInfo' });
    const { t } = useTranslation('forms');

    // Pre-fill the fields if in edit mode
    useEffect(() => {
        if (user) {
            setValue('firstName', user.firstName);
            setValue('lastName', user.lastName);
            setValue('salutation', user.salutation);
            setValue('dateOfBirth', user.dateOfBirth && dayjs(user.dateOfBirth).format('DD.MM.YYYY'));
            setValue('email', user.email);
            setValue('phoneNumber', user.phoneNumber);
            setValue('isShareholder', user.isShareholder);
            setValue('isSpecial', user.isSpecial);
            setValue('specialMembershipFee', user.specialMembershipFee);
        }
    }, [user, setValue]);

    return (
        <Card className="flex-1">
            <CardTitle>{p('title')}</CardTitle>
            <CardSubtitle>{p('subtitle')}</CardSubtitle>
            <div className="flex flex-col gap-6">
                <div className="flex gap-6">
                    <div className="flex-1">
                        <Label>{t('firstName')}</Label>
                        <Input
                            placeholder={t('firstName')}
                            {...register('firstName', {
                                required: 'Enter first name',
                            })}
                            error={!!errors.firstName}
                        />
                        <FormError error={errors.firstName} />
                    </div>
                    <div className="flex-1">
                        <Label>{t('lastName')}</Label>
                        <Input
                            placeholder={t('lastName')}
                            {...register('lastName', {
                                required: 'Enter last name',
                            })}
                            error={!!errors.lastName}
                        />
                        <FormError error={errors.lastName} />
                    </div>
                </div>

                <div className="flex gap-6">
                    <div className="flex-1">
                        <Label>{t('salutation')}</Label>
                        <Controller
                            control={control}
                            name="salutation"
                            rules={{ required: 'Select salutation' }}
                            render={({ field: { onChange } }) => (
                                <Select
                                    isSearchable={false}
                                    placeholder={t('selectPlaceholder')}
                                    options={SALUTATIONS}
                                    onChange={(val: any) => onChange(val.value)}
                                    error={!!errors.salutation}
                                    defaultValue={SALUTATIONS.find(s => s.value === user?.salutation)}
                                />
                            )}
                        />
                        <FormError error={errors.salutation} />
                    </div>
                    <div className="flex-1">
                        <Label>{t('dateOfBirth')}</Label>
                        <InputMask
                            component={Input}
                            placeholder={t('datePlaceholder')}
                            mask="__.__.____"
                            replacement={{ _: /\d/ }}
                            error={!!errors.dateOfBirth}
                            {...register('dateOfBirth', {
                                validate: value => validateDate(value) || t('invalidDateError'),
                                required: 'Enter date of birth',
                            })}
                        />
                        <FormError error={errors.dateOfBirth} />
                    </div>
                </div>

                <div className="flex gap-6">
                    <div className="flex-1">
                        <Label>{t('email')}</Label>
                        <Input
                            placeholder={t('email')}
                            {...register('email', { required: 'Enter email' })}
                            error={!!errors.email}
                            disabled={!!user}
                        />
                        <FormError error={errors.email} />
                    </div>
                    <div className="flex-1">
                        <Label>{t('phone')}</Label>
                        <Input
                            placeholder={t('phone')}
                            {...register('phoneNumber', { required: 'Enter phone' })}
                            error={!!errors.phoneNumber}
                        />
                        <FormError error={errors.phoneNumber} />
                    </div>
                </div>

                <div className="flex gap-6">
                    <div className="flex-1">
                        <Label>{t('membershipFee')}</Label>
                        <Input
                            {...register('specialMembershipFee', {
                                validate: value => {
                                    if (watch('isSpecial') && !value) {
                                        return t('membershipFeeRequired');
                                    }
                                },
                            })}
                            error={!!errors.specialMembershipFee}
                            placeholder={t('membershipFeePlaceholder')}
                        />
                        <FormError error={errors.specialMembershipFee} />
                        <div className="flex mt-2 items-center text-zinc-700 text-sm">
                            <Controller
                                control={control}
                                name="isSpecial"
                                defaultValue={user?.isSpecial}
                                render={({ field: { onChange, value } }) => (
                                    <Checkbox onChange={onChange} checked={value}>
                                        {t('specialMember')}
                                    </Checkbox>
                                )}
                            />
                        </div>
                    </div>
                    <div className="flex-1">
                        <ReferencePerson />
                    </div>
                    <div className="flex-1">
                        <Label>{t('defaultLanguage')}</Label>
                        <Controller
                            control={control}
                            name="defaultLanguage"
                            render={({ field: { onChange } }) => (
                                <Select
                                    isSearchable={false}
                                    placeholder={t('selectPlaceholder')}
                                    options={USER_LANGUAGES}
                                    onChange={(val: any) => onChange(val.value)}
                                    defaultValue={USER_LANGUAGES.find(s => s.value === 'DE')}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="flex gap-2 items-center text-zinc-700 text-sm">
                    <Controller
                        control={control}
                        name="isShareholder"
                        render={({ field: { onChange, value } }) => (
                            <Checkbox onChange={onChange} checked={value}>
                                {t('isShareholder')}
                            </Checkbox>
                        )}
                    />
                </div>
            </div>
        </Card>
    );
};
