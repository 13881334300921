import { gql } from '@apollo/client';

import { ANNIVERSARY_DETAILS, PARTNER_DETAILS, USER_CHILDREN, USER_CONTACTS, USER_PETS } from './fragments';

export const USERS = gql`
    query users(
        $search: String
        $sort: UserSortEnum
        $status: [UserStatus]
        $role: [UsersUserStaffRoleChoices]
        $isMember: Boolean
        $isStaff: Boolean
        $hasNegativeBalance: Boolean
        $offset: Int
        $first: Int
    ) {
        users(
            search: $search
            sort: $sort
            status_In: $status
            staffRole_In: $role
            isMember: $isMember
            isStaff: $isStaff
            offset: $offset
            first: $first
            hasNegativeBalance: $hasNegativeBalance
        ) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
            }
            edges {
                node {
                    id
                    email
                    firstName
                    lastName
                    username
                    phoneNumber
                    status
                    dateJoined
                    isMember
                    staffRole
                    membershipEnd
                    balance {
                        currency
                        amount
                    }
                    image {
                        url
                        alt
                    }
                    lastInvoice {
                        id
                        createdAt
                    }
                    balanceEntries {
                        totalCount
                        edges {
                            node {
                                id
                                amount
                                note
                                createdAt
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const USER_BY_ID = gql`
    ${PARTNER_DETAILS}
    ${USER_PETS}
    ${USER_CHILDREN}
    ${USER_CONTACTS}
    query user($id: ID!) {
        user(id: $id) {
            id
            memberId
            memberType
            email
            firstName
            lastName
            username
            phoneNumber
            status
            dateJoined
            isMember
            salutation
            dateOfBirth
            membershipStart
            membershipEnd
            isShareholder
            isActive
            maritalStatus
            weddingDate
            jobPosition
            jobInfo
            preferenceComment
            staffRole
            address
            zipCode
            poBox
            city
            suspendedFrom
            suspendedUntil
            birthdayInDays
            weddingAnniversaryInDays
            defaultLanguage
            familyInformation
            leavingDate
            isSpecial
            specialMembershipFee
            balance {
                currency
                amount
            }
            country {
                code
                name
            }
            company {
                id
                name
                vatNumber
            }
            companyName
            referencePerson {
                id
                firstName
                lastName
            }
            likes(first: 100) {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
            dislikes(first: 100) {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
            dietRestrictions {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
            allergies {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
            image {
                url
                alt
            }
            ...Children
            ...Pets
            ...Partner
            ...UserContacts
        }
    }
`;

export const USERS_INVOICES = gql`
    query userInvoices(
        $member: ID!
        $status: [InvoiceStatus]
        $offset: Int
        $first: Int
        $sort: InvoiceSortEnum
        $search: String
    ) {
        invoices(member: $member, status_In: $status, offset: $offset, first: $first, sort: $sort, search: $search) {
            totalCount
            pageInfo {
                hasNextPage
            }
            edges {
                node {
                    id
                    invoiceId
                    status
                    dueDate
                    createdAt
                    type
                    lastReminder
                    document {
                        url
                    }
                }
            }
        }
    }
`;

export const USER_TRANSACTIONS = gql`
    query transactions(
        $member: ID!
        $sort: TransactionSortEnum
        $first: Int
        $search: String
        $offset: Int
        $type: [TransactionType]
    ) {
        transactions(member: $member, sort: $sort, first: $first, search: $search, offset: $offset, type_In: $type) {
            totalCount
            pageInfo {
                hasNextPage
            }
            edges {
                node {
                    id
                    amount
                    currency
                    type
                    time
                    receiptNumber
                }
            }
        }
    }
`;

export const USER_EVENTS = gql`
    query userEvents($id: ID, $offset: Int, $first: Int) {
        user(id: $id) {
            id
            allEvents(offset: $offset, first: $first) {
                totalCount
                edges {
                    node {
                        id
                        name
                        type
                        status
                        date
                    }
                }
                pageInfo {
                    hasNextPage
                }
            }
        }
    }
`;

export const FOOD_PREFERENCES = gql`
    query userPreferences {
        userPreferences {
            id
            name
        }
    }
`;

export const DIET_RESTRICTIONS = gql`
    query dietRestrictions {
        userDietRestrictions {
            id
            name
        }
    }
`;
export const ALLERGIES = gql`
    query userAllergies {
        userAllergies {
            id
            name
        }
    }
`;

export const ANNIVERSARIES = gql`
    ${ANNIVERSARY_DETAILS}
    query anniversaries {
        anniversaries {
            ...AnniversaryDetails
        }
        pastAnniversaries {
            ...AnniversaryDetails
        }
    }
`;
