import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Container } from 'components/Container/Container';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { useTranslation } from 'react-i18next';

import { Anniversaries } from './_components/Anniversaries';
import { DashboardEvents } from './_components/DashboardEvents';
import { DraftMembers } from './_components/DraftMembers';
import { ExpiringMemberships } from './_components/ExpiringMemberships';
import { LowestBalances } from './_components/LowestBalances';
import { NegativeBalances } from './_components/NegativeBalances';
import { NewMembersList } from './_components/NewMembersList';

export const Dashboard = () => {
    const { t } = useTranslation('dashboard');
    return (
        <Container>
            <Breadcrumbs crumbs={['dashboard']} />
            <PageTitle>{t('title')}</PageTitle>

            <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-6 lg:flex-row">
                    <div className="flex flex-col gap-6 w-full lg:w-[44%]">
                        <DraftMembers />
                        <NegativeBalances />
                    </div>
                    <Anniversaries />
                </div>

                <div className="flex flex-col lg:flex-row gap-6">
                    <NewMembersList />
                    <ExpiringMemberships />
                </div>
                {/* <div className="flex flex-col lg:flex-row gap-6">
                    <MonthlyIncome />
                    <LastDayRevenue />
                    <YearlyRevenue />
                </div> */}
                <div className="flex flex-col lg:flex-row gap-6">
                    {/* <UnpaidInvoices /> */}
                    <LowestBalances />
                </div>
                <DashboardEvents />
            </div>
        </Container>
    );
};
