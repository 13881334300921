import { useMutation } from '@apollo/client';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Button } from 'components/Button/Button';
import { Container } from 'components/Container/Container';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { useToast } from 'components/Toast/use-toast';
import { UserPreferences } from 'components/UserPreferences/UserPreferences';
import dayjs from 'dayjs';
import { cn } from 'lib/utils';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { ADD_MEMBER } from 'services/users/mutation';

import { CompanyInfo } from './_components/CompanyInfo/CompanyInfo';
import { FamilyInfo } from './_components/FamilyInfo/FamilyInfo';
import { MemberInfo } from './_components/MemberInfo/MemberInfo';
import { PersonalInfo } from './_components/PersonalInfo/PersonalInfo';
import styles from './NewMember.module.scss';

var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

export const NewMember = () => {
    const [status, setStatus] = useState('DRAFT');
    const methods = useForm({
        mode: 'onChange',
    });
    const [addMember] = useMutation(ADD_MEMBER);
    const { t } = useTranslation('member');
    const { toast } = useToast();
    const navigate = useNavigate();

    const onSubmit = (data: any) => {
        data.status = status;
        data.type = 'INDIVIDUAL';
        // Re-format dates so backend can parse them
        data.weddingDate = data.weddingDate ? dayjs(data.weddingDate, 'DD.MM.YYYY').format('YYYY-MM-DD') : null;
        data.dateOfBirth = data.dateOfBirth ? dayjs(data.dateOfBirth, 'DD.MM.YYYY').format('YYYY-MM-DD') : null;
        // Map food preferences so an array of only IDs is sent
        data.likes = data.likes?.map((l: any) => l.value);
        data.dislikes = data.dislikes?.map((l: any) => l.value);
        data.dietRestrictions = data.dietRestrictions?.map((l: any) => l.value);
        data.allergies = data.allergies?.map((l: any) => l.value);
        data.specialMembershipFee = data.specialMembershipFee ? data.specialMembershipFee : null;

        addMember({ variables: { input: data } })
            .then((res: any) => {
                if (res.errors?.length > 0) {
                    toast({
                        title: t('updateErrorTitle'),
                        description: res.errors[0].message,
                        variant: 'warning',
                    });
                } else {
                    toast({
                        description: t('createSuccess'),
                        variant: 'success',
                    });
                    navigate(AppRoutes.memberPreview.replace(':id', res.data.memberCreate.user.id), { replace: true });
                }
            })
            .catch(e => {
                toast({
                    title: t('updateErrorTitle'),
                    description: t('updateErrorMessage'),
                    variant: 'warning',
                });
            });
    };

    return (
        <Container>
            <Breadcrumbs crumbs={['dashboard', 'members', 'new member']} />
            <PageTitle className={cn('py-8', styles.pageTitle)} withBack>
                {t('title')}
                <div className={cn('ml-5', styles.status)}>{t('statusDraft')}</div>
            </PageTitle>

            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="flex flex-col gap-6">
                        <div className="flex gap-6">
                            <MemberInfo />
                            <PersonalInfo />
                        </div>
                        <FamilyInfo />
                        <CompanyInfo />
                        <UserPreferences />
                    </div>
                    <div className="py-8 flex justify-end gap-2">
                        <Button variant="outline" onClick={() => setStatus('DRAFT')}>
                            {t('saveDraft')}
                        </Button>
                        <Button onClick={() => setStatus('ACTIVE')}>{t('savePublish')}</Button>
                    </div>
                </form>
            </FormProvider>
        </Container>
    );
};
