import { useMutation } from '@apollo/client';
import { Avatar } from 'components/Avatar/Avatar';
import { Balance } from 'components/Balance/Balance';
import { Button } from 'components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/Table/Table';
import { useToast } from 'components/Toast/use-toast';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EMAIL_NEGATIVE_BALANCES } from 'services/users/mutation';
import { UserType } from 'types/user';

type NegativeBalancesModalProps = {
    isOpen: boolean;
    onClose: () => void;
    members: UserType[];
};
export const NegativeBalancesModal: React.FC<NegativeBalancesModalProps> = ({ isOpen, onClose, members }) => {
    const [allSelected, setAllSelected] = useState(false);
    const [selectedMembers, setSelectedMembers] = useState<any>([]);
    const { t } = useTranslation('dashboard');
    const [negativeBalancesMutation, { loading }] = useMutation(EMAIL_NEGATIVE_BALANCES);
    const { toast } = useToast();

    const selectAll = () => {
        if (allSelected) {
            setSelectedMembers([]);
            setAllSelected(false);
            return;
        }
        setAllSelected(true);
        setSelectedMembers(members.map(m => m.id));
    };

    const selectMember = (id: string) => {
        if (allSelected) setAllSelected(false);
        if (selectedMembers.includes(id)) {
            setSelectedMembers(selectedMembers.filter((member: any) => member !== id));
            return;
        }
        setSelectedMembers([...selectedMembers, id]);
    };

    const notifyMembers = async () => {
        if (!selectedMembers.length) return;

        const resp = await negativeBalancesMutation({
            variables: { members: selectedMembers },
        });
        if (resp?.data?.sendEmailNegativeBalance?.success) {
            toast({
                title: t('negativeBalancesSuccess'),
                variant: 'success',
            });
            onClose();
            setSelectedMembers([]);
        } else {
            toast({
                title: t('failToastTitle'),
                description: t('failToastSubtitle'),
                variant: 'warning',
            });
        }
    };

    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={800} uncloseable>
                <div className="flex flex-col gap-6">
                    <div>
                        <div className="text-xl font-semibold text-zinc-700 mb-2">
                            {t('negativeBalancesModalTitle')}
                        </div>
                        <div className="text-sm text-zinc-500">{t('negativeBalancesModalSubtitle')}</div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <Table className="border">
                            <TableHeader>
                                <TableRow>
                                    <TableHead className="flex gap-2 items-center">
                                        <Checkbox checked={allSelected} onChange={() => selectAll()} />
                                        {t('nameLabel')}
                                    </TableHead>
                                    <TableHead />
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {members?.length > 0 &&
                                    members.map((member: any) => (
                                        <TableRow
                                            key={member.id}
                                            onClick={() => selectMember(member.id)}
                                            className="cursor-pointer"
                                        >
                                            <TableCell className="flex gap-2 items-center w-full">
                                                <Checkbox
                                                    onChange={() => selectMember(member.id)}
                                                    checked={selectedMembers.includes(member.id)}
                                                />
                                                <Avatar image={member.image} size={32} />
                                                {member.firstName} {member.lastName}
                                            </TableCell>
                                            <TableCell className="text-orange-700">
                                                <Balance balance={member.balance} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                        <div className="flex justify-end gap-2">
                            <Button
                                variant="outline"
                                disabled={selectedMembers.length === 0 || loading}
                                onClick={() => notifyMembers()}
                            >
                                {t('sendEmailButton')}
                            </Button>
                            <Button onClick={onClose}>{t('closeButton')}</Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </Portal>
    );
};
