import { useQuery } from '@apollo/client';
import { Button } from 'components/Button/Button';
import { Card, CardTitle } from 'components/Card/Card';
import { Icon } from 'components/Icon/Icon';
import { cn, normalizeArray } from 'lib/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { EVENTS } from 'services/events/query';

import { DashboardEventsGrid } from './DashboardEventsGrid';
import { DashboardEventsList } from './DashboardEventsList';

export const DashboardEvents = () => {
    const { t } = useTranslation('dashboard');
    const [tab, setTab] = useState('GRID');

    const { data } = useQuery(EVENTS, {
        variables: {
            sort: 'DATE_ASCENDING',
            status: ['UPCOMING'],
            first: 6,
        },
    });

    console.log(data);

    return (
        <Card className="border flex flex-col gap-6 relative">
            <CardTitle className="text-base flex justify-between">
                <div>{t('eventsTitle')}</div>
                <div className="flex gap-4">
                    <Tabs active={tab} onSetTab={setTab} />
                    <Button asChild variant="link">
                        <Link to={AppRoutes.events}>{t('viewAllButton')}</Link>
                    </Button>
                </div>
            </CardTitle>
            {data?.events?.edges?.length === 0 && <NoEvents />}
            {tab === 'LIST' && <DashboardEventsList events={normalizeArray(data?.events)} />}
            {tab === 'GRID' && <DashboardEventsGrid events={normalizeArray(data?.events)} />}
        </Card>
    );
};

const Tabs = ({ active, onSetTab }: any) => {
    return (
        <div className="bg-rose-50 p-[5px] flex h-[42px]">
            <div
                className={cn('py-1.5 px-3 hover:cursor-pointer hover:bg-white', active === 'GRID' && 'bg-white')}
                onClick={() => onSetTab('GRID')}
            >
                <Icon name="grid" className="text-base block" />
            </div>
            <div
                className={cn('py-1.5 px-3 hover:cursor-pointer hover:bg-white', active !== 'GRID' && 'bg-white')}
                onClick={() => onSetTab('LIST')}
            >
                <Icon name="list" className="text-base block" />
            </div>
        </div>
    );
};

const NoEvents = () => {
    const { t } = useTranslation('dashboard');

    return (
        <div className="flex flex-col items-center max-w-[420px] mx-auto gap-6">
            <img src="/images/events.png" alt="Events placeholder" />
            <div className="text-center">
                <div className="text-zinc-700 font-semibold text-3xl">{t('noEventsTitle')}</div>

                <div className="text-center">{t('noEventsText')}</div>
            </div>
            <Button>{t('eventsButton')}</Button>
        </div>
    );
};
