import { useMutation, useQuery } from '@apollo/client';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Button } from 'components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import { DocumentsTab } from 'components/DocumentsTabs/DocumentsTabs';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { Pagination } from 'components/Pagination/Pagination';
import { SearchFilter } from 'components/SearchFilter/SearchFilter';
import { SortFilter } from 'components/SortFilter/SortFilter';
import { StatusFilter } from 'components/StatusFilter/StatusFilter';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/Table/Table';
import { useToast } from 'components/Toast/use-toast';
import dayjs from 'dayjs';
import { normalizeArray } from 'lib/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { EXPORT_CONSUMPTIONS } from 'services/users/mutation';
import { USER_TRANSACTIONS } from 'services/users/query';
import { useUserStore } from 'stores/user.store';

import { ConfirmConsumptionsModal } from './ConfirmConsumptionsModal';

const PAGE_SIZE = parseInt(localStorage.getItem('pageSize') as any) || 10;
const TYPES = ['PAYMENT', 'REFUND', 'CHARGE_BACK'];
const SORT = [
    { value: 'HIGHEST_AMOUNT', label: 'Highest amount' },
    { value: 'LOWEST_AMOUNT', label: 'Lowest amount' },
    { value: 'NEWEST_DATE', label: 'Newest date' },
    { value: 'OLDEST_DATE', label: 'Oldest date' },
];

export const MemberConsumptions = () => {
    const user = useUserStore(state => state.user);
    const [allSelected, setAllSelected] = useState(false);
    const [selectedConsumptions, setSelectedConsumptions] = useState<any>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [exportConsumptionsMutation, { loading }] = useMutation(EXPORT_CONSUMPTIONS);
    const { toast } = useToast();

    const { t } = useTranslation('documents');
    const [searchParams] = useSearchParams();

    const { data, refetch } = useQuery(USER_TRANSACTIONS, {
        variables: { member: user?.id, first: PAGE_SIZE },
    });
    useEffect(() => {
        const search = searchParams.get('search');
        const sort = searchParams.get('sort');
        const type = searchParams.get('type')?.split(',');
        const page = searchParams.get('page') || '1';

        refetch({
            search: search,
            sort: sort,
            type: type,
            offset: (parseInt(page) - 1) * PAGE_SIZE,
        });
    }, [searchParams, refetch]);

    const selectAll = () => {
        if (allSelected) {
            setSelectedConsumptions([]);
            setAllSelected(false);
            return;
        }
        setAllSelected(true);
        setSelectedConsumptions(data?.transactions?.edges.map((t: any) => t.node.id));
    };

    const selectConsumption = (id: string) => {
        if (allSelected) setAllSelected(false);
        if (selectedConsumptions.includes(id)) {
            setSelectedConsumptions(selectedConsumptions.filter((member: any) => member !== id));
            return;
        }
        setSelectedConsumptions([...selectedConsumptions, id]);
    };

    const exportConsumptions = () => {
        if (!selectedConsumptions.length) return;
        setIsOpen(true);
    };

    const confirmExportConsumptions = async () => {
        if (!selectedConsumptions.length) return;
        const resp = await exportConsumptionsMutation({
            variables: {
                transactions: selectedConsumptions,
            },
        });
        if (resp?.data?.exportConsumptions?.file) {
            window.open(resp?.data?.exportConsumptions?.file, '_blank');
            setIsOpen(false);
            setSelectedConsumptions([]);
            successToast();
        } else {
            failToast();
        }
    };

    const successToast = () => {
        toast({
            title: t('successExportTitle'),
            variant: 'success',
        });
    };

    const failToast = () => {
        toast({
            title: t('failToastTitle'),
            description: t('failToastSubtitle'),
            variant: 'warning',
        });
    };

    if (!user) return null;

    return (
        <div>
            <Breadcrumbs crumbs={['dashboard', 'consumptions', `${user.firstName} ${user.lastName} invoices`]} />
            <DocumentsTab />
            <PageTitle withBack>
                {user.firstName} {user.lastName}'s consumptions
            </PageTitle>

            <div className="flex justify-between">
                <div className="flex gap-4 flex-wrap pb-4">
                    <SearchFilter placeholder={t('searchInvoicesPlaceholder')} />
                    <StatusFilter options={TYPES} label="Type" filterKey="type" />
                    <SortFilter options={SORT} />
                </div>
                <div>
                    <Button onClick={() => exportConsumptions()}>Export</Button>
                </div>
            </div>

            <Table className="border">
                <TableHeader>
                    <TableRow>
                        <TableHead className="flex gap-2 items-center">
                            <Checkbox checked={allSelected} onChange={() => selectAll()} />
                            {t('dateLabel')}
                        </TableHead>
                        <TableHead>{t('receiptLabel')}</TableHead>
                        <TableHead>{t('typeLabel')}</TableHead>
                        <TableHead>{t('amountLabel')}</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody className="hover:cursor-pointer">
                    {data?.transactions?.edges?.length > 0 &&
                        normalizeArray(data.transactions).map((transaction: any) => (
                            <TableRow
                                key={transaction.id}
                                className="text-zinc-700 hover:bg-rose-50"
                                onClick={() => selectConsumption(transaction.id)}
                            >
                                <TableCell className="flex gap-2 items-center w-full">
                                    <Checkbox
                                        onChange={() => selectConsumption(transaction.id)}
                                        checked={selectedConsumptions.includes(transaction.id)}
                                    />
                                    {dayjs(transaction.time).format('DD.MM.YYYY')}
                                </TableCell>
                                <TableCell>{transaction.receiptNumber}</TableCell>
                                <TableCell>{t(transaction.type)}</TableCell>
                                <TableCell>
                                    {transaction.currency} {transaction.amount}
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            {data?.transactions?.totalCount === 0 && (
                <div className={'py-5 text-center text-zinc-700'}>{t('noTransactions')}</div>
            )}
            <Pagination pageInfo={data?.transactions?.pageInfo} total={data?.transactions?.totalCount} />
            <ConfirmConsumptionsModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                onConfirm={() => confirmExportConsumptions()}
                isLoading={loading}
            />
        </div>
    );
};
